var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom",class:_vm.params.bg_primary},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"wt-footer-bot-left"},[_c('span',{staticClass:"copyrights-text"},[_vm._v("© "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.name))]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"wt-footer-bot-right"},[_c('ul',{staticClass:"copyrights-nav pull-right"},[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                          name: 'news-category-slug'
                          , params: {
                              category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'lagence', _vm.$store.state.i18n.routes['news-category-slug'].entities.category)
                              , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'informations-legales', _vm.$store.state.i18n.routes['news-category-slug'].entities.slug)
                          }
                      })}},[_vm._v("\n                          "+_vm._s(_vm.$t('Informations légales'))+"\n                      ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"conseils",attrs:{"to":_vm.getLocalizedRoute({
                          name: 'news-category-slug'
                          , params: {
                              category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'lagence', _vm.$store.state.i18n.routes['news-category-slug'].entities.category)
                              , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'honoraires', _vm.$store.state.i18n.routes['news-category-slug'].entities.slug)
                          }
                      })}},[_vm._v("\n                          "+_vm._s(_vm.$t('Honoraires'))+"\n                      ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                          name: 'page'
                          , params: {
                              page: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'contact', 'webPage')
                          }
                      })}},[_vm._v("\n                          "+_vm._s(_vm.$t('Contactez nous'))+"\n                      ")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"mailto:contact@graines-digitales.online","alt":"CMS Headless, Site web Nuxt JS, Application Mobile Ionic"}},[_c('span',{staticClass:"text-secondary"},[_vm._v("Designed By Graines Digitales")])])
}]

export { render, staticRenderFns }